import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  padding: 120px 0 80px 0;
  @media (max-width: 990px) {
    padding: 60px 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0 40px 0;
  }
`

export const CookieWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    justify-content: space-between;
  }
`

export const CookieText = styled.div`
  color: ${themeGet("colors.textColor", "#ffffff")};
  line-height: 24px;
`

export default SectionWrapper
