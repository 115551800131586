import React from "react"

import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"
import CookiePolicy from "../containers/CookiePolicy"
import Layout from "../containers/Layout"

const CookiePage = () => {
  return (
    <>
      <SEO
        title="Elegant Cookie Policy"
        shortDesc="Elegant Beauty Clinic"
        description="Description Here"
      />
      <Layout>
        <ContentWrapper>
          <CookiePolicy />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default CookiePage
