import Fade from "react-reveal/Fade"
import React from "react"

import Container from "../../components/UI/Container"
import SectionHeading from "../../components/SectionHeading"
import SectionWrapper, { CookieText, CookieWrapper } from "./cookiePolicy.style"

const CookiePage = () => {
  return (
    <SectionWrapper>
      <Container noGutter>
        <Fade up delay={200}>
          <SectionHeading mb="64px" title="Cookie Policy" as="h1" />
        </Fade>
        <CookieWrapper>
          <Fade up delay={200}>
            <CookieText>
              <p>
                Elegant Aesthetic Beauty Clinic engages third parties, which use
                Web beacons, images, and scripts, to help better manage content
                on Elegant Aesthetic Beauty Clinic’s Web site. Elegant Aesthetic
                Beauty Clinic does not provide Personal Information to the third
                parties but may tie the information gathered from third party
                tracking to Visitors’ Personal Information for marketing
                purposes.
              </p>
              <h2>Cookies</h2>
              <h3>Google Analytics</h3>
              <p>_ga</p>
              <p>_gat</p>
              <p>_gat_gtag_UA_107219682_1</p>
              <p>_gid</p>
              <p>
                Google Analytics cookies are used to collect information about
                how Visitors use our site. We use the information to compile
                reports and to help us improve the site. The cookies collect
                information in an anonymous form, including the number of
                Visitors to the site, where Visitors have come to the site from,
                and the pages they visited. Opt Out
              </p>
              <h2>Use of Cookies by Elegant Aesthetic Beauty Clinic</h2>
              <p>
                Cookies are small text files placed on an individual’s computer
                by sites that the individual has visited. They are used to make
                Web sites work more effectively and efficiently. Cookies may
                also provide information to the owner of a Web site. No Personal
                Information is stored within them; however, full details are
                below to describe the cookies Elegant Aesthetic Beauty Clinic
                and its service providers use, and to explain why they are used.
                On this page, We provide a list of all the cookies and services
                that may set cookies on Your device. We also provide links to
                each of the third-party services’ privacy policies and optout
                information. Not all third party services provide information to
                opt out of their cookies. If You do not wish to have cookies set
                on Your device for any reason, You may opt out of all cookies
                via Your browser.
              </p>
              <h2>Performance Cookies</h2>
              <p>
                These cookies help Us understand how Visitors interact with our
                Web sites by providing information about the areas visited, the
                time spent on the Web site, and any issues encountered, such as
                error messages. This helps us improve the performance of our Web
                sites.
              </p>
              <h2>Third Party Services</h2>
              <h3>YouTube (player)</h3>
              <p>
                YouTube sets a number of cookies on any page that embeds a
                YouTube video. While we have no control over these cookies, they
                may include a mixture of pieces of information to measure the
                number and behavior of YouTube viewers, to hold information
                about current viewing video settings as well as a personal
                identification token, if you are logged into YouTube.
              </p>
              <h3>Highcharts__cfduid</h3>
              <p>
                The __cfduid cookie is used to override any security
                restrictions based on the IP address the visitor is coming from.
                For example, if the visitor is in a coffee shop where there are
                a bunch of infected machines, but the visitor’s machine is known
                trusted, then the cookie can override the security setting. It
                does not correspond to any userid in the web application, nor
                does the cookie store any personally identifiable information.
                Note: This cookie is strictly necessary for site security
                operations and can’t be turned off.
              </p>
              <h2>Opt Out of All Cookies</h2>
              <p>
                Chrome:&nbsp;
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=3421433"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opt Out
                </a>
              </p>
              <p>
                Explorer:&nbsp;
                <a
                  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opt Out
                </a>
              </p>
              <p>
                Firefox:&nbsp;
                <a
                  href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opt Out
                </a>
              </p>
              <p>
                Safari:&nbsp;
                <a
                  href="https://support.apple.com/kb/index?page=search&amp;q=cookies%20safari"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opt Out
                </a>
              </p>
              <p>
                Other browsers: Please refer to your browser options for further
                information.
              </p>
              <h2>Use of Information Collected</h2>
              <p>
                Elegant Aesthetic Beauty Clinic may occasionally run
                competitions or other special promotions on the Web site, in
                which Visitors are invited to participate, and in connection
                with such activities, Elegant Aesthetic Beauty Clinic may ask
                Visitors to submit contact information (for example, an e-mail
                address) or demographic information (for example, their post
                code, employing industry, or country of residence). Elegant
                Aesthetic Beauty Clinic may use the data collected in these
                competitions and promotions to send promotional material about
                Elegant Aesthetic Beauty Clinic to the Visitor who submitted
                such information. Contact information collected from these
                competitions and promotions may be used to administer the
                competition and notify winners and contact Visitors when
                necessary. Except as described in the Privacy Statement, Elegant
                Aesthetic Beauty Clinic will not give, sell, rent, or loan any
                identifiable Personal Information to any third party, without a
                Visitor’s prior consent. Elegant Aesthetic Beauty Clinic may
                disclose such information to respond to subpoenas, court orders,
                or legal process, or to establish or exercise its legal rights
                or defend against legal claims. Elegant Aesthetic Beauty Clinic
                may also share such information if the Company believes it is
                necessary in order to investigate, prevent, or take action
                regarding illegal activities, suspected fraud, situations
                involving potential threats to the physical safety of any
                person, violations of Elegant Aesthetic Beauty Clinic’s terms of
                service or other, related contract terms, or as otherwise
                required by law. Elegant Aesthetic Beauty Clinic may also
                provide non-personal, summary or group statistics about Elegant
                Aesthetic Beauty Clinic’s Customers, sales, traffic patterns,
                and related Service information to reputable third-party
                vendors, but such statistical data will not include Personal
                Information. If Elegant Aesthetic Beauty Clinic is involved in a
                merger, acquisition, or sale of all or a portion of its assets,
                Visitors will be notified via a prominent notice on Elegant
                Aesthetic Beauty Clinic’s Web site of any change in ownership or
                uses of Personal Information, as well as any choices Visitors
                may have regarding their Personal Information. If you turn off
                all cookies, some or all of our site may not perform properly.
                You can turn them off by using the settings in your browser. For
                further guidance about cookies, see{" "}
                <a href="https://www.aboutcookies.org/">www.aboutcookies.org</a>{" "}
                and{" "}
                <a href="https://www.allaboutcookies.org/">
                  www.allaboutcookies.org
                </a>
              </p>
            </CookieText>
          </Fade>
        </CookieWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default CookiePage
